<template>
    <div class="container pt-4">
        <Title title="Políticas de privacidad y seguridad" class="mb-4"></Title>
<div class="d-block">
<h5 class="color-principal">1. Alcance</h5>
<p class="mb-3">Esta Política de Protección de Datos Personales se aplicará a todas las Bases de Datos y/o Archivos que contengan Datos Personales que sean objeto de Tratamiento por parte de la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> cada una individualmente considerada como responsable del tratamiento de Datos Personales.</p>

<h5 class="color-principal">2. Identificación del Responsable del Tratamiento de Datos Personales</h5>
<p class="mb-3">La <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>. con domicilio en la Calle 41 No 31 – 17 Oficina 212 de la ciudad de Santa Marta, Colombia. Correo electrónico centraltsm@terminaldesantamarta.com, teléfono +57 (5) 4368192 - 4302042 en el área de Gerencia Administrativa.</p>

<h5 class="color-principal">3. Definiciones</h5>
<div class="d-block mb-3">
<ul>
    <li>Autorización: Consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de Datos Personales.</li>
    <li>Aviso de Privacidad: Comunicación verbal o escrita generada por el Responsable, dirigida al Titular para el Tratamiento de sus Datos Personales, mediante la cual se le informa acerca de la existencia de las Políticas de Tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las finalidades del Tratamiento que se pretende dar a los datos personales.</li>
    <li>Base de Datos: Conjunto organizado de Datos Personales que sea objeto de Tratamiento.</li>
    <li>Clientes: Persona natural o jurídica, pública o privada con los cuales <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> tiene una relación comercial.</li>
    <li>Dato Personal: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables. Son algunos ejemplos de datos personales los siguientes: nombre, cédula de ciudadanía, dirección, correo electrónico, número telefónico, estado civil, datos de salud, huella dactilar, salario, bienes, estados financieros, etc.</li>
    <li>Dato sensible: Información que afecta la intimidad del Titular o cuyo uso indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición así como los datos relativos a la salud, a la vida sexual y los datos biométricos, entre otros, la captura de imagen fija o en movimiento, huellas digitales, fotografías, iris, reconocimiento de voz, facial o de palma de mano, etc.</li>
    <li>Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de Datos Personales por cuenta del responsable del Tratamiento. En los eventos en que el responsable no ejerza como Encargado de la Base de Datos, se identificará expresamente quién será el Encargado.
• Participantes: Persona natural y jurídicas que utilizan los servicios prestados por la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>.</li>
    <li>Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la Base de Datos y/o el Tratamiento de los datos.</li>
    <li>Reclamo: Solicitud del Titular del dato o de las personas autorizadas por éste o por la Ley para corregir, actualizar o suprimir sus datos personales o para revocar la autorización en los casos establecidos en la Ley.</li>
    <li>Términos y Condiciones: marco general en el cual se establecen las condiciones para los participantes de actividades promocionales o afines.</li>
    <li>Titular: Persona natural cuyos Datos Personales sean objeto de Tratamiento.</li>
    <li>Transferencia: La transferencia de datos tiene lugar cuando el responsable y/o Encargado del Tratamiento de datos personales, ubicado en Colombia, envía la información o los datos personales a un receptor, que a su vez es Responsable del Tratamiento y se encuentra dentro o fuera del país.</li>
    <li>Transmisión: Tratamiento de Datos Personales que implica la comunicación de los mismos dentro o fuera del territorio de la República de Colombia cuando tenga por objeto la realización de un Tratamiento por el Encargado por cuenta del responsable.</li>
    <li>Tratamiento: Cualquier operación o conjunto de operaciones sobre Datos Personales, tales como la recolección, almacenamiento, uso, circulación o supresión.</li>
</ul>
</div>

<h5 class="color-principal">3. Tipo de Información sujeta a Tratamiento</h5>
<p>La sociedad reconoce que sus empleados, accionistas y miembros de Junta Directiva tienen derecho a contar con una expectativa razonable de su privacidad, teniendo en todo caso para ello en cuenta sus responsabilidades, derechos y obligaciones con la Sociedad.</p>
<p>En virtud de la relación que se establezca entre usted y la Sociedad, ésta recolecta, almacena datos personales tales como:</p>


<h6 class="font-weight-bold">1- De los Candidatos</h6>
<ul>
    <li>a. Nombre, identificación, dirección, teléfono, fecha de nacimiento, información de estudios.</li>
    <li>b. Hoja de vida, educación, experiencia, vínculos con entidades, vínculos con empresas.</li>
</ul>
<h6 class="font-weight-bold">2- De los Clientes</h6>
<ul>
    <li>a. Nombre del Cliente o razón social, número de identificación o NIT con dígito de verificación, lugar de domicilio, dirección, teléfonos, fax, correo electrónico;</li>
    <li>b. Nombre del gerente general o representante legal y dirección, teléfonos, fax, correo electrónico;</li>
    <li>c. Nombre del asignado para el recaudo de cartera, correo electrónico;</li>
    <li>e. Información tributaria;</li>
    <li>f. Información bancaria que incluye nombre del titular de la cuenta bancaria, número de la cuenta bancaria y nombre o código del banco.</li>
</ul>
<h6 class="font-weight-bold">3- De los Proveedores.</h6>
<ul>
    <li>a. Nombre del Proveedor o razón social, número de identificación o NIT con dígito de verificación, lugar de domicilio, dirección, teléfonos, fax, correo electrónico;</li>
    <li>b. Nombre del gerente general o representante legal y dirección, teléfonos, fax, correo electrónico;</li>
    <li>c. Nombre del gerente o coordinador de ventas, dirección, teléfonos, fax, correo electrónico;</li>
    <li>d. Nombre del asignado para el recaudo de cartera, correo electrónico;</li>
    <li>f. Información tributaria;</li>
    <li>g. Información bancaria que incluye nombre del titular de la cuenta bancaria, número de la cuenta bancaria y nombre o código del banco.</li>
</ul>

<h5 class="color-principal">4- De los Empleados</h5>
<ul class="mb-3">
    <li>a. Trabajador y Grupo Familiar: nombre, identificación, dirección, teléfono, nombre de esposa e hijos, nombre e identificación de hijos, historia médica, afiliaciones a la seguridad social, póliza médica, edad, fecha de nacimiento, información de estudios, estado de salud, medicamentos que utilizan, autorizaciones médicas,</li>
    <li>b. Hoja de vida, educación, experiencia, vínculos con entidades, vínculos con empresas;</li>
    <li>c. Salario y otros pagos;</li>
    <li>d. Saldo de deudas contraídas para con la CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA o libranza;</li>
    <li>e. Afiliaciones con descuento de nómina;</li>
    <li>f. Aportes pensionales;</li>
    <li>g. Constitución y aportes a fondos de pensiones voluntarias, bonos de alimentos, etc.;</li>
    <li>h. Procesos judiciales, embargo;</li>
    <li>i. Deudas a favor de cooperativas;</li>
    <li>j. Autorizaciones de descuentos;</li>
    <li>k. Información de afiliación a fondos de empleados, gremiales;</li>
    <li>l. Prestaciones durante toda su vida laboral;</li>
    <li>m. Contrato laboral;</li>
    <li>n. Cambios en el contrato de trabajo;</li>
    <li>o. Vinculación con empleadores anteriores;</li>
    <li>p. Historia laboral del trabajador;</li>
    <li>q. Pago de auxilio y beneficios;</li>
    <li>r. Beneficiarios del trabajador para efecto del pago de auxilios y beneficios;</li>
    <li>s. Afiliación EPS, fondo de pensiones, ARL, Caja de compensación;</li>
    <li>t. Capacitaciones recibidas;</li>
    <li>w. Informe demográfico de los trabajadores;</li>
    <li>x. Historia clínica ocupacional del trabajador;</li>
    <li>y. Accidentes laborales;</li>
    <li>z. Horas extras;</li>
    <li>aa. Ingreso y salida de la planta; bb. Huella dactilar;</li>
    <li>cc. Registro fotográfico;</li>
</ul>
<p>Si dentro de la información recolectada se encuentran datos sensibles, la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> le informará de la calidad de dicho datos sensibles y la finalidad del tratamiento, y sólo serán tratados con su consentimiento previo, expreso e informado. Tenga en cuenta que por tratarse de datos sensibles usted no está obligado a autorizar su tratamiento.</p>

<h6 class="font-weight-bold">4. Principios Aplicables al Tratamiento de Datos Personales</h6>
<ul>
    <li>Para el Tratamiento de los Datos Personales, la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> aplicará los principios que se mencionan a continuación, los cuales constituyen las reglas a seguir en la recolección, manejo, uso, tratamiento, almacenamiento e intercambio, de datos personales:</li>
<li>Legalidad: El Tratamiento de datos personales se realizará conforme a las disposiciones legales aplicables (Ley Estatutaria 1581 de 2012 y sus decretos reglamentarios).</li>
<li>Finalidad: Los datos personales recolectados serán utilizados para un propósito específico y explícito el cual debe ser informado al Titular o permitido por la Ley. El Titular será informado de manera clara, suficiente y previa acerca de la finalidad de la información suministrada.</li>
<li>Libertad: La recolección de los Datos Personales solo podrá ejercerse con la autorización, previa, expresa e informada del Titular.</li>
<li>Veracidad o Calidad: La información sujeta al Tratamiento de Datos Personales debe ser veraz, completa, exacta, actualizada, comprobable y comprensible.</li>
<li>Transparencia: En el Tratamiento de Datos Personales se garantiza el derecho del Titular a obtener en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernan.</li>
<li>Acceso y circulación restringida: El Tratamiento de datos personales solo podrá realizarse por las personas autorizadas por el Titular y/o por las personas previstas en la Ley.</li>
<li>Seguridad: Los Datos Personales sujetos a Tratamiento se manejarán adoptando todas las medidas de seguridad que sean necesarias para evitar su pérdida, adulteración, consulta, uso o acceso no autorizado o fraudulento.</li>
<li>Confidencialidad: Todos los funcionarios que trabajen en la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> están obligados a guardar reserva sobre la información personal a la que tengan acceso con ocasión de su trabajo en la entidad.</li>
</ul>

<h5 class="color-principal">5. Tratamiento y Finalidades al cual serán sometidos los Datos Personales tratados por la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong></h5>
<p>Tratamiento de Datos Personales, para el adecuado desarrollo de sus actividades comerciales, así como para el fortalecimiento de sus relaciones con terceros, recolecta, almacena, usa, circula y suprime Datos Personales correspondientes a personas naturales con quienes tiene o ha tenido relación, tales como, sin que la enumeración signifique limitación, trabajadores y familiares de éstos, participantes, aliados, distribuidores, proveedores, acreedores y deudores, para los siguientes propósitos o finalidades:</p>
<strong>5.1. Finalidades generales para el tratamiento de Datos Personales Los datos personales son utilizados para:</strong>
<ul>
    <li>a. Ejecución del contrato suscrito con cualquiera de la Compañía.</li>
    <li>b. Pago de obligaciones contractuales.</li>
    <li>c. Envío de información a entidades gubernamentales o judiciales por solicitud expresa de la misma.</li>
    <li>d. Soporte en procesos de auditoría externa/interna.</li>
    <li>e. Envío/Recepción de mensajes con fines comerciales, publicitarios y/o de atención al cliente.</li>
<li>f. Registro de la información de los candidatos, clientes, empleados y proveedores en la base de datos de las Compañía.</li>
<li>g. Contacto con candidatos, clientes, empleados o proveedores para el envío de información relacionado con la relación contractual, comercial y obligacional que tenga lugar.</li>
<li>h. Recolección de datos para el cumplimiento de los deberes que como Responsable de la información y datos personales, le corresponde a la Compañía.</li>
<li>i. Con propósitos de seguridad o prevención de fraude.</li>
<li>j. Para proporcionarle un efectivo servicio al cliente.</li>
<li>k. Cualquier otra finalidad que resulte en el desarrollo del contrato o la relación entre usted y la Sociedad.</li>
</ul>
<ul>
<li>Controlar el acceso a las oficinas de la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> y establecer medidas de seguridad;</li>
<li>Dar respuesta a consultas, peticiones, quejas y reclamos que sean realizadas por los Titulares y organismos de control y trasmitir los Datos Personales a las demás autoridades que en virtud de la ley aplicable deban recibir los Datos Personales; tales como los requeridos en el artículo 631 del</li>
<li>E.T. y los demás dispuestos por la ley.</li>
<li>Registrar sus datos personales en los sistemas de información de la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> y en sus bases de datos comerciales y operativas;</li>
<li>Cualquier otra actividad de naturaleza similar a las anteriormente descritas que sean necesarias para desarrollar el objeto social de la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>.</li>
</ul>

<strong>5.2. Respecto a los datos personales de nuestros CLIENTES:</strong>
<ul>
<li>Para cumplir las obligaciones contraídas por la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> con sus Clientes, aliados y participantes al momento de adquirir nuestros servicios;</li>
<li>Enviar información sobre cambios en las condiciones de los servicios ofrecidos por la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>;</li>
<li>Enviar información sobre ofertas relacionadas con nuestros servicios que ofrece la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>;</li>
<li>Informar sobre nuevos servicios que estén relacionados con el o los contratado(s) o adquirido(s);</li>
<li>Lograr una eficiente comunicación relacionada con nuestros productos, servicios, ofertas, promociones, alianzas, estudios, concursos, contenidos, así como los de nuestros vinculados;</li>
<li>Para el fortalecimiento de las relaciones con sus Clientes, mediante el envío de información relevante, la toma de pedidos y evaluación de la calidad del servicio;</li>
<li>Para la determinación de obligaciones pendientes, la consulta de información financiera e historia crediticia y el reporte a centrales de información de obligaciones incumplidas, respecto de sus deudores;</li>
<li>Permitir que aliados de la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>, con las cuales ha celebrado contratos que incluyen disposiciones para garantizar la seguridad y el adecuado tratamiento de los datos personales tratados, contacten al Titular con el propósito de ofrecerle bienes o servicios de su interés;</li>
<li>Controlar el acceso a las oficinas de la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> y establecer medidas de seguridad;</li>
<li>Utilizar los distintos servicios a través de los sitios web de la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>, incluyendo descargas de contenidos y formatos;</li>
<li>Para fines establecido en el artículo 631 del E.T. y los demás dispuestos por la ley.</li>
</ul>

<strong>5.3. Respecto a los datos personales de nuestros EMPLEADOS:</strong>
<ul>
<li>Administrar y operar, directamente o por conducto de terceros, los procesos de selección y vinculación de personal, incluyendo la evaluación y calificación de los participantes y la verificación de referencias laborales y personales, y la realización de estudios de seguridad;</li>
<li>Desarrollar las actividades propias de la gestión de Recursos Humanos dentro de la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>, tales como nómina, afiliaciones a entidades del sistema general de seguridad social, actividades de bienestar y salud ocupacional, ejercicio de la potestad sancionatoria del empleador, entre otras;</li>
<li>Realizar los pagos necesarios derivados de la ejecución del contrato de trabajo y/o su terminación, y las demás prestaciones sociales a que haya lugar de conformidad con la ley aplicable;</li>
<li>Contratar beneficios laborales con terceros, tales como seguros de vida, gastos médicos, entre otros;</li>
<li>Notificar a contactos autorizados en caso de emergencias durante el horario de trabajo o con ocasión del desarrollo del mismo;</li>
<li>Coordinar el desarrollo profesional de los empleados, el acceso de los empleados a los recursos informáticos del empleador y dar soporte para su utilización;</li>
<li>Planificar actividades empresariales;</li>
<li>Para fines establecido en el artículo 631 del E.T. y los demás dispuestos por la ley.</li>
</ul>

<div class="d-block my-2 font-weight-bold">5.4. Respecto a los Datos de PROVEEDORES:</div>
<ul class="mb-2">
<li>Para invitarlos a participar en procesos de selección y a eventos organizados o patrocinados por la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>;</li>
<li>Para la evaluación del cumplimiento de sus obligaciones;</li>
<li>Para hacer el registro en los sistemas de la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>;</li>
<li>Para procesar sus pagos y verificar saldos pendientes;</li>
<li>Para fines establecido en el artículo 631 del E.T. y los demás dispuestos por la ley.</li>
</ul>

<h5 class="color-principal">6. Derechos de los Titulares de los Datos Personales</h5>
<p>Las personas naturales cuyos Datos Personales sean objeto de Tratamiento por parte de la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>, tienen los siguientes derechos, los cuales pueden ejercer en cualquier momento:</p>
<p>6.1 Conocer los Datos Personales sobre los cuales la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> está realizando el Tratamiento. De igual manera, el Titular puede solicitar en cualquier momento, que sus datos sean actualizados o rectificados, por ejemplo, si encuentra que sus datos son parciales, inexactos, incompletos, fraccionados, induzcan a error, o aquellos cuyo Tratamiento esté expresamente prohibido o no haya sido autorizado.</p>
<p>6.2 Solicitar prueba de la autorización otorgada a la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> para el Tratamiento de sus Datos Personales.</p>
<p>6.3 Ser informado por la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>, previa solicitud, respecto del uso que ésta le ha dado a sus Datos Personales.</p>
<p>6.4 Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la Ley de Protección de Datos Personales.</p>
<p>6.5 Solicitar a la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> la supresión de sus Datos Personales y/o revocar la autorización otorgada para el Tratamiento de los mismos, mediante la presentación de un reclamo, de acuerdo con los procedimientos establecidos en el numeral 13 de esta Política. No obstante, la solicitud de supresión de la información y la revocatoria de la autorización no procederán cuando el Titular de la información tenga un deber legal o contractual de permanecer en la Base de Datos y/o Archivos, ni mientras se encuentre vigente la relación entre el Titular y la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>, en virtud de la cual fueron recolectados sus datos.</p>
<strong>6.6 Acceder de forma gratuita a sus Datos Personales que hayan sido objeto de Tratamiento. Los derechos de los Titulares podrán ejercerse por las siguientes personas:</strong>
<ul>
<li>Por el Titular;</li>
<li>Por sus causahabientes, quienes deberán acreditar tal calidad;</li>
<li>Por el representante y/o apoderado del Titular, previa acreditación de la representación o apoderamiento;</li>
<li>Por estipulación a favor de otro o para otro.</li>
</ul>

<h5 class="color-principal">7. Deberes de la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> como Responsable del Tratamiento de Datos Personales <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong></h5>
<p>que los Datos Personales son de propiedad de las personas a las que se refieren y solamente ellas pueden decidir sobre los mismos. En ese sentido, <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> hará uso de los Datos Personales recolectados únicamente para las finalidades para las que se encuentra debidamente facultada y respetando, en todo caso, la normatividad vigente sobre la Protección de Datos Personales.</p>
<p>LA <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> atenderá los deberes previstos para los Responsables del Tratamiento, contenidos en el artículo 17 de la Ley 1581 de 2012 y las demás normas que la reglamenten, modifiquen o sustituyan.</p>


<h5 class="color-principal">8. Área Responsable de la Implementación y Observancia de esta Política</h5>
<p>El área de Gerencia Administrativa de la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> tiene a su cargo la labor de desarrollo, implementación, capacitación y observancia de ésta Política. Para el efecto, todos los funcionarios que realizan el Tratamiento de Datos Personales en las diferentes áreas de la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>, están obligados a reportar estas Bases de Datos al área de Gerencia y a dar traslado a ésta de manera inmediata, de todas las peticiones, quejas o reclamos que reciban por parte de los Titulares de Datos Personales.</p>

<p class="mb-2">El área de Gerencia Administrativa de la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> también ha sido designada como área responsable de la atención de peticiones, consultas, quejas y reclamos ante la cual el Titular de la información podrá ejercer sus derechos a conocer, actualizar, rectificar y suprimir el dato y revocar la autorización. Esta área se encuentra ubicada en el domicilio: Calle 41 No 31 – 17 Oficina 212 de la ciudad de Santa Marta, Colombia, y puede ser contactada a través del correo electrónico: <a href="mailto:centraltsm@terminaldesantamarta.com">centraltsm@terminaldesantamarta.com</a></p>

<h5 class="color-principal">9. Autorización</h5>
<p>La <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> solicitará autorización previa, expresa e informada a los Titulares de los Datos Personales sobre los que requiera realizar el Tratamiento.</p>
<p>Esta manifestación de voluntad del Titular puede darse a través de diferentes mecanismos puestos a disposición por la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>, tales como:</p>

<ul>
<li>Por escrito y/o vía mail, diligenciando un formato de autorización para el Tratamiento de Datos Personales determinado por <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>.</li>
<li>Mediante conductas inequívocas que permitan concluir que otorgó su autorización, a través de su aceptación expresa a los Términos y Condiciones de una actividad dentro de los cuales se requiera la autorización de los participantes para el Tratamiento de sus Datos Personales. IMPORTANTE: En ningún caso la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> asimilará el silencio del Titular a una conducta inequívoca.</li>
</ul>


<h5 class="color-principal">10. Disposiciones Especiales para el Tratamiento de Datos Personales.</h5>
<p>10.1 Tratamiento de Datos Personales de Naturaleza Sensible
El Tratamiento de los Datos Personales de naturaleza sensible está prohibido por la ley, salvo que se cuente con autorización expresa, previa e informada del Titular, entre otras excepciones consagradas en el Artículo 6º de la Ley 1581 de 2012.</p>

<p>En este caso, además de cumplir con los requisitos establecidos para la autorización, la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> informará al Titular:</p>
<ul>
<li>Que por tratarse de datos sensibles no está obligado a autorizar su Tratamiento.</li>
<li>Cuáles de los datos que serán objeto de Tratamiento son sensibles y la finalidad del Tratamiento.</li>
</ul>
<p>10.2 Tratamiento de Datos Personales de Niños, Niñas y Adolescentes<br>
Según lo dispuesto por el Artículo 7º de la Ley 1581 de 2012 y el artículo 12 del Decreto 1377 de 2013, <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> sólo realizará el Tratamiento, correspondientes a niños, niñas y adolescentes, siempre y cuando este Tratamiento responda y respete el interés superior de los niños, niñas y adolescentes y asegure el respeto de sus derechos fundamentales.</p>
<p>Cumplidos los anteriores requisitos, la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> deberá obtener la Autorización del representante legal del niño, niña o adolescente, previo ejercicio del menor de su derecho a ser escuchado, opinión que será valorada teniendo en cuenta la madurez, autonomía y capacidad para entender el asunto.</p>

<h5 class="color-principal">11. Procedimiento para Atención y Respuesta a Peticiones, Consultas, Quejas y Reclamos de los Titulares de Datos Personales</h5>
<p>Los Titulares de Datos Personales tratados por la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> tienen derecho a acceder a sus Datos Personales y a los detalles de dicho Tratamiento, así como a rectificarlos y actualizarlos en caso de ser inexactos o a solicitar su eliminación cuando consideren que resulten ser excesivos o innecesarios para las finalidades que justificaron su obtención u oponerse al Tratamiento de los mismos para fines específicos.</p>
<p>Las vías que se han implementado para garantizar el ejercicio de dichos derechos a través de la presentación de la solicitud respectiva son:</p>
<ul>
<li>Comunicación dirigida a Gerencia Administrativa de la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> Calle 41 No 31 – 17 Oficina 212 de la ciudad de Santa Marta.</li>
<li>Solicitud presentada al correo electrónico: <a href="mailto:centraltsm@terminaldesantamarta.com">centraltsm@terminaldesantamarta.com</a></li>
<li>Solicitud presentada a través del teléfono <strong>+57 (5) 4368192 - 4302042</strong></li>
</ul>
<p>Estos canales podrán ser utilizados por Titulares de datos personales, o terceros autorizados por ley para actuar en su nombre, con el objeto de ejercer los siguientes derechos:</p>
<strong>1.1 Procedimiento para la realización de peticiones y consultas</strong>
<ul>
<li>(i) El Titular podrá consultar sus datos personales en cualquier momento. Para tal fin, podrá elevar una solicitud indicando la información que desea conocer, a través de cualquiera de los mecanismos arriba señalados.</li>
<li>(ii) El Titular o sus causahabientes deberán acreditar su identidad, la de su representante, la representación o estipulación a favor de otro o para otro. Cuando la solicitud sea formulada por persona distinta del Titular y no se acredite que la misma actúa en representación de aquél, se tendrá por no presentada.</li>
<li>(iii) La consulta y/o petición debe contener como mínimo el nombre y dirección de contacto del Titular o cualquier otro medio para recibir la respuesta, así como una descripción clara y precisa de los datos personales respecto de los cuales el Titular busca ejercer el derecho de consulta y/o petición.</li>
<li>(iv) Si la consulta y/o petición realizada por el Titular del dato resulta incompleta, la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> requerirá al interesado dentro de los cinco (5) días siguientes a la recepción de la consulta y/o petición para que subsane las fallas. Transcurridos dos</li>
<li>(2) meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido de su consulta.</li>
<li>(v) Las peticiones y/o consultas serán atendida por la <strong>CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo de la misma. Cuando no fuere posible atender la petición o consulta dentro de dicho término, este hecho se informará al solicitante, expresando los motivos de la demora y señalando la fecha en que se atenderá su petición consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.</li>
</ul>
<strong>11.2 Procedimiento para la realización de quejas y reclamos</strong>
<p>De conformidad con lo establecido en el Artículo 14 de la Ley 1581 de 2012, cuando el Titular o sus causahabientes consideren que la información tratada por la<strong> CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong> deba ser objeto de corrección, actualización o supresión, o cuando deba ser revocada por advertirse el presunto incumplimiento de cualquiera de los deberes contenidos en la Ley, podrán presentar una solicitud ante la<strong> CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA</strong>, la cual será tramitada bajo las siguientes reglas:</p>
<ul>
    <li>(i) El Titular o sus causahabientes deberán acreditar su identidad, la de su representante, la representación o estipulación a favor de otro o para otro. Cuando la solicitud sea formulada por persona distinta del Titular y no se acredite que la misma actúa en representación de aquél, se tendrá por no presentada.</li>
</ul>
<p>La solicitud de rectificación, actualización, supresión o revocatoria debe ser presentada a través de los medios habilitados por la CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA indicados en el presente documento y contener, como mínimo, la siguiente información:</p>
<ul>
<li>El nombre y dirección de domicilio del Titular o cualquier otro medio para recibir la respuesta.</li>
<li>Los documentos que acrediten la identidad del solicitante y en caso dado, la de su representante con la respectiva autorización.</li>
<li>La descripción clara y precisa de los datos personales respecto de los cuales el Titular busca ejercer alguno de los derechos y la solicitud concreta.</li>
</ul>
<ul>
<li>(ii) Si la solicitud se presenta incompleta, la CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA deberá requerir al interesado dentro de los cinco (5) días siguientes a su recepción para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido de su solicitud.</li>
<li>(iii) En caso de que quien reciba la solicitud no sea competente para resolverlo, dará traslado al área de Legal de la CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA, en un término máximo de dos (2) días hábiles e informará de la situación al interesado.</li>
<li>(iv)El término máximo para atender esta solicitud será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atenderla dentro de dicho término, se informará al interesado sobre los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.</li>
</ul>

<h5 class="color-principal">12. Seguridad de los Datos Personales</h5>
<p>La CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA, en estricta aplicación del Principio de Seguridad en el Tratamiento de Datos Personales, proporcionará las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento. La obligación y responsabilidad de la CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA se limita a disponer de los medios adecuados para este fin. La CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA no garantiza la seguridad total de su información ni se responsabiliza por cualquier consecuencia derivada de fallas técnicas o del ingreso indebido por parte de terceros a la Base de Datos o archivo en los que reposan los Datos Personales objeto de Tratamiento por parte de la CENTRAL DE TRANSPORTE DE SANTA MARTA LTDA y sus Encargados.</p>
<p>Exigirá a los proveedores de servicios que contrata, la adopción y cumplimiento de las medidas técnicas, humanas y administrativas adecuadas para la protección de los Datos Personales en relación con los cuales dichos proveedores actúen como Encargados.</p>

<h5 class="color-principal">13. Legislación Aplicable</h5>
<p>Esta Política de Protección de Datos Personales, el Aviso de Privacidad, y el Anexo de Formato de Autorización que hace parte de esta Política, se rigen por lo dispuesto en la legislación vigente sobre protección de los Datos Personales a los que se refieren el Artículo 15 de la Constitución Política de Colombia, la Ley 1266 de 2008, la Ley 1581 de 2012, el Decreto 1377 de 2013, el Decreto 1727 de 2009 y demás normas que las modifiquen, deroguen o sustituyan.</p>

<h5 class="color-principal">16. Vigencia</h5>
<p>Esta Política de Protección de Datos Personales está vigente desde el 1 de Julio de 2017</p>

</div>


    </div>
</template>
<template src="@/views/politicas-privacidad/politicas-privacidad-template.vue"></template>
<style src="@/views/politicas-privacidad/politicas-privacidad.css"></style>

<script>
import Title from '@/components/global/Title.vue';
export default {
    name: 'PoliticasPrivacidad',
    components: {
        Title
    }
}
</script>